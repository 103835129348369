<template>
  <div>
    <div>
      <div class="submit-step" :class="{'submit-step-over': step >= 1}">1.申诉账号</div>
      <div class="submit-step" :class="{'submit-step-over': step >= 2}">2.联系方式</div>
      <div class="submit-step" :class="{'submit-step-over': step >= 3}">3.验证联系方式</div>
      <div class="submit-step" :class="{'submit-step-over': step >= 4}">4.账号信息</div>
      <div class="submit-step" :class="{'submit-step-over': step >= 5}">5.完成申诉</div>
    </div>
    <div class="submit-step-form" :style="{'display': step === 1 ? 'block' : 'none'}">
      <el-form :model="accountAppealForm" status-icon :rules="step1rules" ref="step1rules"
               label-width="180px"
               class="demo-ruleForm" size="mini">
        <div class="input-block-title">账号申诉</div>
        <div class="line"></div>
        <p>
          账号申诉，是指通过官方的网址，填写丢失号码上面的一些早期资料，通过后台审核来确定账号的真实性。<br>
          申诉结果一般在三个工作日内发送到您的联系手机和邮箱，<span style="color:#f90;">申诉期间账号可以正常使用</span><br>
          申诉成功后，您可以修改您的基本资料
        </p>
        <el-form-item label="申诉账号" prop="account">
          <el-col :span="9">
            <el-input v-model="accountAppealForm.account" autocomplete="off" placeholder="帐号或者手机号"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onAppealBeginClick('step1rules')">开始申诉</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="submit-step-form" :style="{'display': step === 2 ? 'block' : 'none'}">
      <el-form :model="accountAppealForm" status-icon :rules="step2rules" ref="step2rules"
               label-width="180px"
               class="demo-ruleForm" size="mini">
        <div class="input-block-title">填写申诉联系方式</div>
        <div class="line"></div>
        <el-form-item label="申诉账号">
          {{ accountAppealForm.account }}
        </el-form-item>
        <el-form-item label="联系方式" prop="contactType">
          <el-radio-group v-model="accountAppealForm.contactType" @change="clearRules">
            <el-radio :label="1" >通过手机号联系</el-radio>
            <el-radio :label="2" >通过邮箱联系</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="联系号码" prop="contactNumber">
          <el-col :span="9">
            <el-input v-model="accountAppealForm.contactNumber" placeholder="手机号或邮箱"></el-input>
          </el-col>
        </el-form-item>
        <div class="input-block-title">填写申诉基本资料</div>
        <div class="line"></div>
        <el-form-item label="真实姓名" prop="realName">
          <el-col :span="9">
            <el-input v-model="accountAppealForm.realName" placeholder="真实姓名"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="证件号码" prop="idCard">
          <el-col :span="9">
            <el-input v-model="accountAppealForm.idCard" placeholder="证件号码"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="证件照片" prop="frontIdCard">
          <el-upload
            :http-request="fileUploadFunction"
            :data="{...uploadEnum.AUTHENTICATION}"
            action=""
            class="uploader"
            :show-file-list="false"
            :on-success="frontIdCardUploadSuccess"
            :before-upload="beforePicUpload">
            <img v-if="accountAppealForm.frontIdCard"
                 :src="accountAppealForm.frontIdCard"
                 class="uploader-icon" alt>
            <i v-else class="el-icon-plus uploader-icon"></i>
            <div class="el-upload__tip">(正面照)</div>
          </el-upload>
          <el-upload
            :http-request="fileUploadFunction"
            class="uploader"
            :data="{...uploadEnum.AUTHENTICATION}"
            action=""
            :show-file-list="false"
            :on-success="backIdCardUploadSuccess"
            :before-upload="beforePicUpload">
            <img v-if="accountAppealForm.backIdCard"
                 :src="accountAppealForm.backIdCard"
                 class="uploader-icon" alt>
            <i v-else class="el-icon-plus uploader-icon"></i>
            <div class="el-upload__tip">(反面照)</div>
          </el-upload>
          <p style="margin-left: 50px; float: left;">
            (选填)<br>
            1、上传证件照可以提高申诉成功率<br>
            2、每张图片大小不得超过2MB<br>
            3、若无身份证照片，可上传其他证件照<br>
            4、证件照片仅用于申诉账号身份核实，绝无他用
          </p>
        </el-form-item>
        <el-form-item>
          <el-button @click="step--">上一步</el-button>
          <el-button type="primary" @click="nextStep('step2rules')">下一步</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="submit-step-form" :style="{'display': step === 3 ? 'block' : 'none'}">
      <el-form :model="accountAppealForm" status-icon :rules="step3rules" ref="step3rules"
               label-width="180px"
               class="demo-ruleForm" size="mini">
        <div class="input-block-title">验证-联系方式</div>
        <div class="line"></div>
        <el-form-item :label="accountAppealForm.contactType == 1 ? '验证手机' : '验证邮箱'">
          {{ accountAppealForm.contactNumber }}
          <template v-if="isClickSendCode">
            <br>
            验证码已发送至您的{{ accountAppealForm.contactType == 1 ? '手机' : '邮箱' }}
            {{ accountAppealForm.contactNumber }}，请注意查收
          </template>
          <template v-else>
            <el-button class="mg-l10" size="mini" @click="sendCaptcha">发送验证码</el-button>
          </template>
        </el-form-item>
        <el-form-item label="验证码" prop="captcha" style="margin-bottom: 20px;">
          <el-col :span="9">
            <el-input v-model="accountAppealForm.captcha" placeholder="验证码"></el-input>
          </el-col>
          <el-col :span="16">如果没收到验证码，请点击“<span style="color:#f90;"
                                                @click="sendCaptcha">{{ captchaText }}</span>”，或者返回上一步更换联系号码
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button @click="step--">上一步</el-button>
          <el-button type="primary" @click="nextStep('step3rules')">下一步</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="submit-step-form" :style="{'display': step === 4 ? 'block' : 'none'}">
      <el-form :model="accountAppealForm" status-icon :rules="step4rules" ref="step4rules"
               label-width="180px"
               class="demo-ruleForm" size="mini">
        <div class="input-block-title">账号信息</div>
        <div class="line"></div>
        <el-form-item label="申诉账号">
          {{ accountAppealForm.account }}
        </el-form-item>
        <el-form-item label="注册地点" prop="registerProvince">
          <el-col :span="4">
            <el-select @change="changregisterProvince" v-model="accountAppealForm.registerProvince"
                       placeholder="请选择省份">
              <el-option :label="province.name"
                         :value="province.name"
                         v-for="(province) in provinceList"
                         :key="province.id"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="accountAppealForm.registerCity" placeholder="请选择城市">
              <el-option :label="city.name"
                         :value="city.name"
                         v-for="(city) in cityList(accountAppealForm.registerProvince)"
                         :key="city.id"
              ></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="常登录地" prop="usuallyProvince">
          <el-col :span="4">
            <el-select @change="changusuallyProvince" v-model="accountAppealForm.usuallyProvince"
                       placeholder="请选择省份">
              <el-option :label="province.name"
                         :value="province.name"
                         v-for="(province) in provinceList"
                         :key="province.id"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="accountAppealForm.usuallyCity" placeholder="请选择城市">
              <el-option :label="city.name"
                         :value="city.name"
                         v-for="(city) in cityList(accountAppealForm.usuallyProvince)"
                         :key="city.id"
              ></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="账号绑定的微信号码" prop="bindWeChat">
          <el-col :span="9">
            <el-input v-model="accountAppealForm.bindWeChat" placeholder="账号绑定的微信号码"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="账号绑定的QQ号码" prop="bindQq">
          <el-col :span="9">
            <el-input v-model="accountAppealForm.bindQq" placeholder="账号绑定的QQ号码"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="账号大致余额" prop="balance">
          <el-col :span="9">
            <el-input v-model="accountAppealForm.balance" placeholder="账号大致余额"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="帐号类型">
          <el-radio-group v-model="accountAppealForm.accounttype" @change="changeacctype">
            <el-radio :label="1">个人</el-radio>
            <el-radio :label="2">企业</el-radio>
            <el-radio :label="4">个体工商户</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="帐号认证实名信息" prop="certifiedCompany">
          <el-col :span="9">
            <el-input v-model="accountAppealForm.certifiedCompany"
                      :placeholder="placeholder"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button @click="step--">上一步</el-button>
          <el-button type="primary" @click="submitAccountAppeal">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="submit-step-form" :style="{'display': step === 5 ? 'block' : 'none'}"
         style="text-align: center;">
      <img style="margin-top: 20px;" src="/images/accountappeal/success.jpg"/>
      <div style="margin-top: 20px;">提交申诉成功！</div>
      <div style="margin-top: 20px;">我们将会尽快审核您的申诉，并将结果发送至您的联系号码，请注意查收。</div>
    </div>
    <el-dialog class="captcha-dialog" center :visible.sync="captchaDialogShowFlag">
      <div class="captcha" ref="captcha" style="height: 220px"></div>
    </el-dialog>
  </div>
</template>

<script>
import {captcha} from '@/apis/index'
import area from '../../../resources/js/area'
import uploadEnum from "@/resources/js/uploadenum";
import $ from "jquery";

require('@ASSET/libs/jigsaw/jigsaw.min')
const config = require('../../../resources/js/config')
export default {
  name: 'accountAppealSubmit',
  components: {},
  data() {
    return {
      uploadEnum,
      step: 1,
      flag:false,
      captchaDialogShowFlag: false,
      isClickSendCode: false,
      placeholder: '请输入姓名',
      captchaCountDown: 0,
      captchaText: '获取验证码',
      config,
      provinceList: area.area.province_list,
      accountAppealForm: {
        account: null,
        contactType: 1,
        contactNumber: null,
        realName: null,
        idCard: null,
        frontIdCard: null,
        backIdCard: null,
        captcha: null,
        registerProvince: null,
        registerCity: null,
        usuallyProvince: null,
        usuallyCity: null,
        bindWeChat: null,
        bindQq: null,
        balance: null,
        certifiedCompany: null,
        accounttype: 1,
      },
      step1rules: {
        account: [{
          trigger: 'blur',
          required: true,
          validator: (rule, value, callback) => {
            if (value && this.flag) {
              this.$request.post({
                reqcode: '1104',
                reqdesc: '能否提交账号申诉',
                account: value,
                invalid_cache: new Date().getTime(),
              }).then(res => {
                if (res.retdata) {
                  callback()
                } else {
                  callback(new Error(res.retDesc))
                }
                this.flag = false
              });
            } else if (value==null || value.length<=0){
              callback(new Error('手机号或者帐号'))
            }
          }
        }]
      },
      step2rules: {
        contactType: [{
          trigger: 'change',
          required: true,
          message: '禁止为空'
        }],
        contactNumber: [{
          trigger: 'blur',
          required: true,
          validator: (rule, value, callback) => {
            if (this.accountAppealForm.contactType === 1) {
              /^1[3-9]\d{9}$/.test(value) ? callback() : callback(new Error('手机号非法'))
            } else {
              /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value) ? callback() : callback(
                new Error('邮箱非法'))
            }
          }
        }],
        realName: [{
          trigger: 'blur',
          required: true,
          validator: (rule, value, callback) =>
            /^[\u4e00-\u9fa5]{2,10}$/.test(value) ? callback() : callback(new Error('真实姓名非法'))
        }],
        idCard: [{
          trigger: 'blur',
          required: true,
          validator: (rule, value, callback) =>
            /(^\d{15}$)|(^\d{18}$)|(^\d{17}([\dXx])$)/.test(value) ? callback() : callback(
              new Error('身份证号码非法'))
        }]
      },
      step3rules: {
        captcha: [{
          trigger: 'blur',
          required: true,
          validator: (rule, value, callback) => {
            if (!value || value.length !== 6) {
              callback(new Error('验证码长度有误'))
            } else {
              this.$request.post({
                reqcode: '1099',
                reqdesc: 'reqdesc',
                type: this.accountAppealForm.contactType,
                number: this.accountAppealForm.contactNumber,
                captcha: this.accountAppealForm.captcha
              }).then(res => {
                callback()
              }).catch(res => {
                callback(new Error(res.retDesc))
              })
            }
          }
        }]
      },
      step4rules: {}
    }
  },
  watch: {
    step(val) {
      if (val === 3 && this.captchaCountDown === 0) {
        this.resetDoSendCode();
      }
    }
  },
  methods: {
    initCaptcha() {
      console.log("888888")
      return new Promise(resolve => {
        if (!this.captchaDialogShowFlag) {
          this.captchaDialogShowFlag = true;
        }
        this.$nextTick(() => {
          this.$refs.captcha.innerHTML = '';
          window.jigsaw.init({
            el: this.$refs.captcha,
            onSuccess: () => {
              $('.captcha').html('')
              console.log(55)
              this.captchaDialogShowFlag = false;
              resolve(true)
            },
            onFail: () => {
              console.log(888)
              resolve(false)
            },
            onRefresh: () => {
              console.log(888)
            }
          })

        })
      })
    },
    clearRules(){
      this.$refs['step2rules'].clearValidate()
    },
    resetDoSendCode() {
      this.isClickSendCode = false;
    },
    changeacctype() {
      if (this.accountAppealForm.accounttype !== 1) {
        this.placeholder = "请输入企业名称";
      } else {
        this.placeholder = "请输入姓名";
      }
    },
    changregisterProvince() {
      this.accountAppealForm.registerCity = null;
    },
    changusuallyProvince() {
      this.accountAppealForm.usuallyCity = null;
    },
    cityList(provinceName) {
      for (const provinceCode in this.provinceList) {
        if (this.provinceList[provinceCode].name === provinceName) {
          const cityList = {}
          for (const cityCode in area.area.city_list) {
            if ((cityCode + '').substring(0, 2) === (provinceCode + '').substring(0, 2)) {
              cityList[cityCode] = area.area.city_list[cityCode]
            }
          }
          return cityList
        }
      }
      return []
    },
    onAppealBeginClick(ruleName) {
      // this.$refs[ruleName].validate()
      // .then(() => {
      this.initCaptcha().then(res => {
        if (res) {
          this.flag = true
          this.nextStep(ruleName);
        }
      })
    },
    nextStep(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          this.step++
        } else {
          // this.$message.warning('表单填写错误')
        }
      })
    },
    frontIdCardUploadSuccess(res, file) {
      if (res.code) {
        const {src} = res
        this.accountAppealForm.frontIdCard = src
        this.$message.success('上传文件成功')
      } else {
        this.$message.error('上传文件失败')
      }
    },
    backIdCardUploadSuccess(res, file) {
      if (res.code) {
        const {src} = res
        this.accountAppealForm.backIdCard = src
        this.$message.success('上传文件成功')
      } else {
        this.$message.error('上传文件失败')
      }
    },
    beforePicUpload(file) {
      if (!file.type.startsWith('image')) {
        this.$message.error('上传图片只能是 图片 格式!')
      } else if (file.size / 1024 / 1024 > .5) {
        this.$message.error('上传大小不能超过 1M!')
      } else {
        this.$message('文件正在上传');
        return true
      }
      return false
    },
    doCaptchaCountDown(time) {
      if (time < 1) {
        this.captchaCountDown = 0
        this.captchaText = '重新获取验证码'
      } else {
        this.captchaCountDown = time
        this.captchaText = this.captchaCountDown + '秒后可重新获取验证码'
        setTimeout(() => {
          this.doCaptchaCountDown(--this.captchaCountDown)
        }, 1000)
      }
    },
    sendCaptcha() {
      if (this.captchaCountDown > 0) {
        return
      }
      this.$refs.step2rules.validate((valid) => {
        if (valid) {
          let doSendCode = () => {
            let requestMethod;
            let data = {}
            if (this.accountAppealForm.contactType === 1) {
              requestMethod = captcha.sendPhoneCode;
              data = {
                phone: this.accountAppealForm.contactNumber
              }
            } else if (this.accountAppealForm.contactType === 2) {
              requestMethod = captcha.sendEmailCode;
              data = {
                email: this.accountAppealForm.contactNumber
              }
            }
            this.doCaptchaCountDown(60)
            this.isClickSendCode = true;
            requestMethod(data).then(res => {
              this.$message.warning('验证码发送成功')
            }).catch(res => {
              this.doCaptchaCountDown(0)
              this.$message.warning(res.retDesc)
            })
          }
          this.initCaptcha().then(res => {
            if (res) {
              doSendCode();
            }
          });
        } else {
          this.$message.warning('表单填写错误')
        }
      })
    },
    submitAccountAppeal() {
      this.$refs.step4rules.validate((valid) => {
        if (valid) {
          this.$request.post({
            reqcode: '1100',
            reqdesc: '提交账号申诉',
            data: this.accountAppealForm
          }).then(res => {
            this.step = 5
            this.$message.warning('账号申诉提交成功')
          }).catch(res => {
            this.$message.warning(res.retDesc)
          })
        } else {
          this.$message.warning('表单填写错误')
        }
      })
    }
  }
}
</script>

<style scoped>
.submit-step {
  float: left;
  text-align: center;
  width: calc(20% - 40px);
  margin: 15px 20px;
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
  background: #e6e6e6;
  position: relative;
  padding-left: 20px;
  border-radius: 0 100px 100px 0;
}

.submit-step:before {
  content: ' ';
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  position: absolute;
  background: #FFF;
  left: -22px;
  top: 0;
}

.submit-step-over {
  background: #F90;
  color: #fff;
}

.submit-step-form {
  padding: 26px;
  color: #7d7c7c;
}

.submit-step-form p, .submit-step-form pre {
  color: #999999;
  font-size: 12px;
  margin: 0 0 10px 0;
  line-height: 17px;
}

.submit-step-form .input-block-title {
  color: #8080a1;
  font-size: 13px;
}

.line {
  height: 1px;
  border-bottom: 1px dashed #d4d4d4;
  margin: 5px 0;
}

.uploader {
  width: 80px;
  float: left;
  margin-right: 10px;
}

.uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 60px;
  line-height: 60px;
}

.el-form-item.el-form-item--feedback.el-form-item--mini {
  margin-bottom: 15px;
}

.circle-number {
  float: left;
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin: 5px 5px 0;;
}
</style>
